import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  quoteCard: {
    padding: "20px",
    paddingLeft: "50px",
    boxLizing: "border-box",
    boxShadow: "0 2px 4px rgba(black, 0.12)",
    position: "relative",
    overflow: "hidden",
    minHeight: "120px",
    p: {
      fontSize: "22px",
      lineHeight: "1.5",
      margin: 0,
      maxWidth: "80%",
    },
    flexDirection: "column",
    margin: "auto",
    // textAlign: "center",
    "&::before": {
      fontFamily: "Georgia, serif",
      content: "'“'",
      position: "absolute",
      top: "10px",
      left: "10px",
      fontSize: "5em",
      fontWeight: "normal",
      color: "rgba(52, 52, 52, 0.8)",
    },
    "&::after": {
      fontFamily: "Georgia, serif",
      content: "'”'",
      position: "absolute",
      lineHeight: "100px",
      right: "15px",
      bottom: "-45px",

      fontSize: "5em",
      color: "rgba(52, 52, 52, 0.8)",
      fontWeight: "normal",
    },
  },
  container: {
    padding: "20px",
  },
  roundImage: {
    width: "60px",
    borderRadius: "30px",
  },
  verticalAlignedImage: {
    verticalAlign: "middle",
  },
  signature: {
    marginLeft: "5em",
  },
  block: {
    padding: "20px",
  },
}));

export const WelcomeCard = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  return (
    // <Card>
    //   <CardContent>
    <div className={classes.container}>
      <div>
        <blockquote
          className={classes.quoteCard}
          dangerouslySetInnerHTML={{ __html: t("welcome_section.body") }}
        ></blockquote>
      </div>
      <div className={classes.signature}>
        <cite>
          {" "}
          <img
            src="/images/P1012135.JPG"
            alt="customer relationship team"
            className={clsx(classes.roundImage, classes.verticalAlignedImage)}
          />{" "}
          {t("welcome_section.signature")}
        </cite>
      </div>

      {t("welcome_section.process_explanation") !== "" ? (
        <div className={classes.block}>
          <p>{t("welcome_section.process_explanation")}</p>
        </div>
      ) : null}
    </div>
    //   </CardContent>
    // </Card>
  );
};

export default WelcomeCard;
