import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Tooltip,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const missingPartsReasons = {
  //bed-spring
  "Sommier Bois Massif": [
    "parts_missing",
    "parts_broken",
    "screws_missing",
    "bed_slats_missing",
    "bed_slats_broken"
  ],
  "Sommier Original": [
    "parts_missing",
    "parts_broken",
    "screws_missing",
    "bed_slats_missing",
    "bed_slats_broken"
  ],
  "Sommier Coffre": [
    "parts_missing",
    "parts_broken",
    "screws_missing",
    "bed_slats_missing",
    "bed_slats_broken"
  ],
  "Sommier zoné - électrique": [
    "parts_missing",
    "parts_broken",
    "screws_missing",
    "bed_slats_missing",
    "bed_slats_broken"
  ],
  "Surmatelas": [
    "parts_missing",
    "parts_broken",
    "screws_missing",
    "bed_slats_missing",
    "bed_slats_broken"
  ]
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 200,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  container: {
    display: "flex",
  },
  icon: {
    marginTop: "36px",
    marginLeft: "10px",
  }
}));

export default function ReturnReasonInput(props) {
  const { t } = useTranslation();
  let { onChange, product } = props;
  const [reason, setReason] = useState('');
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl} required error={!reason}>
        <InputLabel id="missing-parts-reason-label">
          {t("form_labels.missing_parts_reason.title")}
        </InputLabel>
        <Select
          labelId="missing-parts-reason-label"
          id="missing-parts-reason"
          onChange={(event) => {
            onChange(event.target.value);
            setReason(event.target.value);
          }}
          value={reason ?? ''}
          input={<Input />}
        >
          {missingPartsReasons?.[product?.title]?.map((reason) => (
            <MenuItem key={reason} value={reason}>
              {t(`form_labels.missing_parts_reasons.${reason}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <HtmlTooltip
          title={
          <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: t('form_labels.missing_parts_reason.info') }} />
            </React.Fragment>
          }
        >
          <InfoIcon color="disabled" className={classes.icon}/>
        </HtmlTooltip>
    </div>
  );
}
