import React from 'react';
import { 
  Grid,
  Typography,
} from '@material-ui/core';

import { InputField } from '../../FormFields';
import { useTranslation } from 'react-i18next';

export default function OrderSearchForm(props) {
  const { t } = useTranslation();
  const {
    formField: {
      order_name,
      customer_email,
    }
  } = props;
  
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t('order_search_page.title')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField 
            name={order_name.name} 
            label={t('form_labels.order_name')} 
            autoComplete='order-name'
            fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField 
            name={customer_email.name} 
            label={t('form_labels.email_address')} 
            autoComplete='email' 
            fullWidth />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
