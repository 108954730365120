import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { InputField } from '../../FormFields';
import { useTranslation } from 'react-i18next';

export default function ReviewOrder(props) {
  const { t } = useTranslation();
  const {
    formField: {
      shipping_address,
    },
  } = props;
  
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t('shipping_info_page.title')}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {t('shipping_info_page.body')}
      </Typography>
      <Grid container spacing={2} style={{padding: '15px'}}>
        <Grid item xs={12} md={6}>
          <InputField 
            name={shipping_address.first_name.name} 
            label={t('form_labels.shipping_address.first_name')}
            style={{width: '100%',}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField 
            name={shipping_address.last_name.name} 
            label={t('form_labels.shipping_address.last_name')}
            style={{width: '100%',}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField 
            name={shipping_address.address1.name} 
            label={t('form_labels.shipping_address.address1')}
            style={{width: '100%',}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField 
            name={shipping_address.address2.name} 
            label={t('form_labels.shipping_address.address2')}
            style={{width: '100%',}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField 
            name={shipping_address.zip.name} 
            label={t('form_labels.shipping_address.zip')}
            style={{width: '100%',}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField 
            name={shipping_address.city.name} 
            label={t('form_labels.shipping_address.city')}
            style={{width: '100%',}}
          />
        </Grid>
        <Grid item xs={12} >
          <InputField 
            name={shipping_address.country.name} 
            label={t('form_labels.shipping_address.country')}
          />
          </Grid>
        </Grid>
    </React.Fragment>
  );
}
