import React, {useState} from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Box,
  IconButton,
  DialogContent,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import {useTranslation} from "react-i18next";

import ExchangeDialog from "../ExchangeDialog";
import RefundDialog from "../RefundDialog";

import {makeStyles} from "@material-ui/core/styles";
import ReplacementDialog from "../ReplacementDialog";
import CoverRequestDialog from "../CoverRequestDialog";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function ReturnTypeDialog(props) {
  const {t} = useTranslation();
  //   const {
  //     onSubmit
  //   } = props;

  const {
    lineItem,
    exchangeProducts,
    handleRefundItem,
    handleExchangeVariant,
    handleReplacement,
    requestCover,
    setBedspringFeilds,
    setIsBedspringFormActive
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isMatteress = lineItem.title.includes('Matelas');
  const isPillow = lineItem.title.includes('Oreiller');
  const isBedSpring = lineItem.title.includes('Sommier');
  const isElectricBedSpring = lineItem.title.includes('électrique');
  const isChair = lineItem.title.includes('Chaise');
  const isSurmatelas = lineItem.title.includes('Surmatelas');
  const isLoop = lineItem.title.includes('Loop');

  /*todo  author : bathri
  *   * move these type of validation to common place
  * */
  const isExchangeableProduct = !isChair && !isBedSpring && !isSurmatelas && !isMatteress && !isLoop;

  return (
    <div style={{textAlign: "center"}}>
      <Button color="primary" onClick={handleClickOpen} variant="contained">
        {t("ask_return")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={"xs"}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{t("return_type_modal.title")}</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon/>
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {isExchangeableProduct && <Grid item xs={12}>
              <ExchangeDialog
                lineItem={lineItem}
                exchangeProducts={exchangeProducts}
                onSubmit={(data) => {
                  handleExchangeVariant(data);
                  handleClose();
                }}
              />
            </Grid>}
            <Grid item xs={12}>
              <RefundDialog
                lineItem={lineItem}
                onSubmit={(data) => {
                  handleRefundItem(data);
                  handleClose();
                }}
              />
            </Grid>
            {(isMatteress || isSurmatelas) && <Grid item xs={12}>
              <ReplacementDialog
                lineItem={lineItem}
                onSubmit={(data) => {
                  handleReplacement(data);
                  handleClose();
                }}
              />
            </Grid>}
            {(isMatteress || isPillow || isSurmatelas) &&
              <Grid item xs={12}>
                <div style={{textAlign: "center"}}>
                  <Button onClick={requestCover} color="primary" variant="contained">{t('ask_cover')}</Button>
                </div>
              </Grid>
            }
            {(isBedSpring && !isElectricBedSpring) && <Grid item xs={12}>
              <div style={{textAlign: "center"}}>
                <Button onClick={() => {
                  setBedspringFeilds({
                    lineItem,
                    fields: [{
                      missingPart: "",
                      quantity: 0,
                    }],
                  })
                  setIsBedspringFormActive(true);
                }} color="primary" variant="contained">{t('request_part')}</Button>
              </div>
            </Grid>}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
