import checkoutFormModel from './returnFormModel';
const {
  formField: {
    order_id,
    order_name,
    customer_email,
  }
} = checkoutFormModel;

export default {
  [order_id.name]: '',
  [order_name.name]: '',
  [customer_email.name]: '',
  // [shipping_address.first_name.name] : '',
  // [shipping_address.last_name.name] : '',
  // [shipping_address.address1.name] : '',
  // [shipping_address.address2.name] : '',
  // [shipping_address.city.name] : '',
  // [shipping_address.company.name] : '',
  // [shipping_address.country.name] : '',
  // [shipping_address.province.name] : '',
  // [shipping_address.zip.name] : '',
  // [shipping_address.phone.name] : '',
};
