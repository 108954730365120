import React from 'react'
import Client from 'shopify-buy'
import {getStorefrontAccessTokenFromUrl} from '../utils/shopifyStorefrontTokenHelper'

export class StoreContextClass {
    products = [];
    exchangeableProducts = [];
    variants = [];
    exchangeableVariants = [];

    constructor(storeURl) {
        this.products = [];
        this.variants = [];
        this.shopUrl = storeURl;
        this.client = Client.buildClient({
            domain: storeURl,
            storefrontAccessToken: getStorefrontAccessTokenFromUrl(storeURl)
        });

        this.client.collection.fetchAllWithProducts({first: 30, productsFirst:30}).then(collections => {
            const exchangeCollection = collections.find(c => c.title.toLowerCase().includes("exchange"))
            const productList = [];
            collections.forEach(c => {
                productList.push(...c.products)
            })

            // exchangeable products
            if (exchangeCollection) {
                this.exchangeableProducts = exchangeCollection.products;
                this.exchangeableProducts.forEach(product => {

                    product.variants.forEach(variant => {
                        this.exchangeableVariants.push(variant);
                    })
                })
            }

            // all product list
            this.products = productList;
            this.products.forEach(product => {

                product.variants.forEach(variant => {
                    this.variants.push(variant);
                })
            })
        })


        // this.client.product.fetchAll().then(products => {
        //   this.products = products;
        //   products.forEach(product => {

        //     product.variants.forEach(variant => {
        //       this.variants.push(variant);
        //     })
        //   })

        // });
    }


    getVariantById = (variantId) => {
        const variant = this.variants.find(variant => {
            const split = variant.id.split('/');
            const id = split[split.length - 1];
            return variantId.toString() === id.toString();
        })
        return variant;
    }

    getSameValueExchangeableProducts = (item_variant) => {
        const exchangeProducts = this.exchangeableProducts.filter(product => {
            return product.variants.find(variant => {
                if (variant && variant.price && variant.price.amount && item_variant && item_variant) {
                    return parseFloat(variant.price.amount) === parseFloat(item_variant.price.amount);
                }

            })
        })
        return exchangeProducts;
    }
}

export const defaultStoreContext = {
    client: {},
    products: [],
    variants: [],
    shopUrl: '',
}

export const StoreContext = React.createContext(defaultStoreContext)

export const withStoreContext = (Component) => {
    return props => (
        <StoreContext.Consumer>
            {context => <Component {...props} storeContext={context}/>}
        </StoreContext.Consumer>
    )
}

export default StoreContext
