const isStaging = require('./isStaging');

const getApiUrl = () => {
  console.log("process.env", process.env);
  let endpoint;
  let protocol = process.env.REACT_APP_LOCAL_RUN ? 'http' : 'https';
  let baseURL = "europe-west1-percko-customer-relationship.cloudfunctions.net";
  if (isStaging()) {
    baseURL = "europe-west1-customer-relationship-staging.cloudfunctions.net";
  }
  if (process.env.REACT_APP_LOCAL_RUN) {
    baseURL = "localhost:5001/percko-customer-relationship/europe-west1";
    if (isStaging()) {
      baseURL = "localhost:5001/customer-relationship-staging/europe-west1";
    }
  }
  endpoint = `${protocol}://${baseURL}/api/v1`;
  console.log("getApiUrl", endpoint);
  return endpoint;
}

module.exports = getApiUrl;