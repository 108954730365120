export const isNewProduct = (productTitle) => {
  let isNew = false;
  switch (productTitle) {
    case "GILET PRATIQUE - FEMME":
    case "GILET PRATIQUE - HOMME":
    case "T-SHIRT DISCRET - FEMME":
    case "T-SHIRT DISCRET - HOMME":
    case "T-SHIRT DE SPORT - FEMME":
    case "T-SHIRT DE SPORT - HOMME":
      isNew = true;
      break;
    default:
      isNew = false;
  }
  return isNew;
}

export const isOldProduct = (productTitle) => {
  let isOld = false;
  switch (productTitle) {
    case "LyneUp":
    case "LyneFit":
    case "LyneHome":
      isOld = true;
      break;
    default:
      isOld = false;
  }
  return isOld;
}