import React, {useState} from "react";
import {
  Typography,
  Select,
  MenuItem,
  Grid,
  Input,
  Button,
  IconButton,
} from "@material-ui/core";
import {DeleteOutline as DeleteOutlineIcon} from "@material-ui/icons";
import useStyles from "../styles";
import SingleLineItem from "./SingleLineItem";
import {withStoreContext} from "../../../context/storeFrontContext";
import {useTranslation} from "react-i18next";
import RefundDialog from "../../RefundDialog";
import MissingPartsReasonInput from "../../MissingPartsReasonInput";

const missingPartsData = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
];

function ReturnedProductSelectForm(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [bedspringFeilds, setBedspringFeilds] = useState(null);

  const handleBedSpringFieldChanges = (index, event) => {
    let data = {...bedspringFeilds};
    data.fields[index][event.target.name] = event.target.value;
    setBedspringFeilds(data);
  };

  const toggleQuantity = (index, value) => {
    let data = {...bedspringFeilds};
    data.fields[index].quantity = value;
    setBedspringFeilds(data);
  };

  const addRemoveFields = (type, index) => {
    let data = {...bedspringFeilds};
    if (type === "add") {
      let newObject = {
        missingPart: "",
        quantity: 0,
      };
      data.fields.push(newObject);
      setBedspringFeilds(data);
    } else {
      const filteredFields = data.fields.filter((item, idx) => idx !== index);
      setBedspringFeilds({...data, fields: filteredFields});
    }
  };

  const {
    formValues,
    setFormValues,
    formValues: {lineItems, orderName: order_name},
    storeContext,
    isBedSpringFormActive,
    setIsBedspringFormActive,
    setIsBackFromBedSpring,
  } = props;


  const isFieldsEmpty = bedspringFeilds && (bedspringFeilds.fields.some(field => (!field.missingPart || field.quantity === 0)));

  return !isBedSpringFormActive ? (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t("products_return_selection.title")} {order_name}
      </Typography>
      {lineItems
        ? lineItems.map((line_item) => {
          const variant = storeContext.getVariantById(line_item.variant_id); //_getShopifyVariant(line_item.variant_id);
          const allowedExchangeProducts =
            storeContext.getSameValueExchangeableProducts(variant);

          return (
            <SingleLineItem
              lineItem={line_item}
              variant={variant}
              allowedExchangeProducts={allowedExchangeProducts}
              setIsBedspringFormActive={setIsBedspringFormActive}
              setBedspringFeilds={setBedspringFeilds}
              bedspringFeilds={bedspringFeilds}
            />
          )
        })
        : null}
    </React.Fragment>
  ) : (
    /*bed spring form*/
    <React.Fragment>
      <div style={{marginLeft: 50}}>
        <Typography variant="h5" style={{fontSize: '20px'}} gutterBottom>
          {t("bedspring_parts_selection.mainTitle")}
        </Typography>
        <Typography variant="span" style={{fontSize: '14px'}} gutterBottom>
          {t("bedspring_parts_selection.title")}
        </Typography>
        {bedspringFeilds && bedspringFeilds.fields.map((field, index) => (
          <Grid
            container
            style={{marginLeft: 10, marginTop: 10}}
            alignItems="center"
          >
            <Typography variant="h6" style={{fontSize: '14px'}}>
              {t("bedspring_parts_selection.missing_piece")}
            </Typography>
            <Select
              labelId="missing-part-label"
              id="missing-part"
              name="missingPart"
              style={{margin: "0px 20px", width: 50}}
              onChange={(event) => handleBedSpringFieldChanges(index, event)}
              value={field.missingPart ?? ""}
            >
              {missingPartsData.map((part) => (
                <MenuItem key={part} value={part}>
                  {part}
                </MenuItem>
              ))}
            </Select>

            <Typography style={{marginLeft: 40, fontSize: '14px'}} variant="h6">
              {t("bedspring_parts_selection.quantity")}
            </Typography>
            <div
              onClick={() =>
                field.quantity > 0 && toggleQuantity(index, field.quantity - 1)
              }
              style={{
                textAlign: "center",
                fontSize: "17px",
                padding: "0px 5px",
                border: "0.5px solid #aaa",
                cursor: "pointer",
                marginLeft: "20px",
              }}
            >
              -
            </div>
            <span
              style={{
                textAlign: "center",
                fontSize: "17px",
                padding: "0px 5px",
                border: "0.5px solid #aaa",
              }}
            >
            {field.quantity}
          </span>
            <div
              onClick={() => toggleQuantity(index, field.quantity + 1)}
              style={{
                textAlign: "center",
                fontSize: "17px",
                padding: "0px 5px",
                cursor: "pointer",
                border: "0.5px solid #aaa",
              }}
            >
              +
            </div>
            <Button
              onClick={() => addRemoveFields("add")}
              style={{marginLeft: 20, backgroundColor: "#000", color: "#FFF", fontSize: '14px'}}
            >
              {t("bedspring_parts_selection.add")}
            </Button>
            {index > 0 && <div
              onClick={() => addRemoveFields("delete", index)}
              style={{marginLeft: 20, cursor: "pointer"}}
            >
              <DeleteOutlineIcon style={{width: 30, height: 30}}/>
            </div>}
          </Grid>
        ))}
      </div>
      <div className={classes.buttons}>
        <Button className={classes.button} onClick={() => {
          setIsBackFromBedSpring(true);
          setIsBedspringFormActive(false);
          setBedspringFeilds(null);
        }}>
          {t("back")}
        </Button>
        <div className={classes.wrapper}>
          <Button
            disabled={isFieldsEmpty}
            onClick={() => {
              if (!isFieldsEmpty) {
                setIsBedspringFormActive(false);
                const newLineItems = [...lineItems]
                const index = newLineItems.findIndex(line_item => line_item.id === bedspringFeilds.lineItem.id);
                newLineItems[index].is_parts_request = true;
                newLineItems[index].partsRequested = bedspringFeilds.fields;
                setFormValues({...formValues, lineItems: newLineItems})
              }
            }}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {t("next")}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withStoreContext(ReturnedProductSelectForm);
