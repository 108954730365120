export default {
  formId: 'returnForm',
  formField: {
    order_id: {
      name: 'order_id',
      label: 'orderId',
    },
    order_name: {
      name: 'order_name',
      label: 'Numéro de commande*', 
      requiredErrorMsg: 'Numéro de commande requis'
    },
    customer_email: {
      name: 'customer_email',
      label: 'Address email*',
      requiredErrorMsg: 'Adresse mail requise'
    },
    shipping_address :{
      first_name: {
        name: 'shipping_address.first_name',
        label: 'Prénom',
        requiredErrorMsg: 'Prénom requis'
      },
      last_name: {
        name: 'shipping_address.last_name',
        label: 'Nom',
        requiredErrorMsg: 'Nom requis'
      },
      address1: {
        name: 'shipping_address.address1',
        label: 'Adresse',
        requiredErrorMsg: 'Adresse requise'
      },
      address2: {
        name: 'shipping_address.address2',
        label: 'Adresse (complément)'
      },
      city: {
        name: 'shipping_address.city',
        label: 'Ville'
      },
      company: {
        name: 'shipping_address.company',
        label: 'company'
      },
      country: {
        name: 'shipping_address.country',
        label: 'country'
      },
      province: {
        name: 'shipping_address.province',
        label: 'province'
      },
      zip: {
        name: 'shipping_address.zip',
        label: 'zip'
      },
      phone: {
        name: 'shipping_address.phone',
        label: 'phone'
      },
    },
  }
};
