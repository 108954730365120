import React from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  body: {
    fontSize: theme.typography.pxToRem(12),
  },
  faq: {
    marginTop: "50px",
  },
  email_capture: {
    maxWidth: "600px;",
    textAlign: "center",
    margin: "auto",
  },
}));

export default function Faq(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={3} className={classes.faq}>
      <Grid item>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {t("faq.find_order_number.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography className={classes.body}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("faq.find_order_number.body"),
                  }}
                ></div>
              </Typography>
              <div className={classes.email_capture}>
                <img
                  src={t("faq.find_order_number.img")}
                  width="100%"
                  alt="email commande"
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1b-content"
            id="panel1b-header"
          >
            <Typography className={classes.heading}>
              {t("faq.return_after_return.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography className={classes.body}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("faq.return_after_return.body"),
                  }}
                ></div>
              </Typography>
              <div className={classes.email_capture}>
                <img
                  src={t("faq.return_after_return.img")}
                  width="100%"
                  alt="email échange"
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1b-content"
            id="panel1b-header"
          >
            <Typography className={classes.heading}>
              {t("faq.refund_multibuy.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography className={classes.body}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("faq.refund_multibuy.body"),
                  }}
                ></div>
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
