// import './App.css';
import React, { Suspense, useEffect } from "react";
import MaterialLayout from "./components/Layout/MaterialLayout";
import ReturnPage from "./components/ReturnPage";
import i18n from 'i18next';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { StoreContext, StoreContextClass } from "./context/storeFrontContext";
import isStaging from "./utils/isStaging";

function App() {

  useEffect(() => {
    i18n.on('initialized', function (options) {
      document.title = i18n.t('header.title');
    })
  }, [])

  return (
    <Suspense fallback="loading">
      <Router>
          <MaterialLayout>
            <Switch>
              <Route exact path="/">
                <Redirect to="/eur" />
              </Route>
              <Route exact path="/eur">
                <StoreContext.Provider
                  value={
                    new StoreContextClass(
                      isStaging()
                        ? "percko-eur-staging.myshopify.com"
                        : "percko-eur.myshopify.com"
                    )
                  }
                >
                  <ReturnPage />
                </StoreContext.Provider>
              </Route>
              <Route exact path="/gbp">
                <StoreContext.Provider
                  value={
                    new StoreContextClass(
                      isStaging()
                        ? "percko-gbp-staging.myshopify.com"
                        : "percko-gbp.myshopify.com"
                    )
                  }
                >
                  <ReturnPage />
                </StoreContext.Provider>
              </Route>
              <Route exact path="/usd">
                <StoreContext.Provider
                  value={
                    new StoreContextClass(
                      isStaging()
                        ? "percko-usd-staging.myshopify.com"
                        : "percko-usd.myshopify.com"
                    )
                  }
                >
                  <ReturnPage />
                </StoreContext.Provider>
              </Route>
              <Route exact path="/cad">
                <StoreContext.Provider
                  value={
                    new StoreContextClass(
                      isStaging()
                        ? "percko-cad-staging.myshopify.com"
                        : "percko-cad.myshopify.com"
                    )
                  }
                >
                  <ReturnPage />
                </StoreContext.Provider>
              </Route>
              <Route>
                <Redirect to="/eur" />
              </Route>
            </Switch>
          </MaterialLayout>
      </Router>
    </Suspense>
  );
}

export default App;
