import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
}
  from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useTranslation } from 'react-i18next';
import CoverRequestReasonInput from '../CoverRequestReasonInput'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function CoverRequestDialog(props) {
  const { t } = useTranslation();
  const {
    onSubmit,
    lineItem,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit({
      reason: reason,
    });
    setOpen(false);
  }

  return (
    <div style={{textAlign: "center"}}>
      <Button color="primary" onClick={handleClickOpen} variant="contained">{t('ask_cover')}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={'xs'}
        maxWidth={'xs'}
      >
        <DialogTitle>{t('cover_request_dialog.title')}</DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate>
            <CoverRequestReasonInput product={lineItem} onChange={setReason} />
            {!reason && <div>{t("form_labels.form_error_reason")}</div>}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={!reason}>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}