import GothamLightWoff2 from './fonts/GothamLight.woff2';
import GothamLightWoff from './fonts/GothamLight.woff';
import GothamLightTtf from './fonts/GothamLight.ttf';

import {
  createMuiTheme,
  responsiveFontSizes,
  makeStyles
} from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';

const GothamLight = {
  fontFamily: 'GothamLight',
  fontStyle: 'light',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('GothamLight'),
    url(${GothamLightWoff2}) format('woff2'),
    url(${GothamLightWoff}) format('woff'),
    url(${GothamLightTtf}) format('ttf'),

  `,
 
};

let theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: teal,
    secondary: teal
  },
  typography: {
    fontFamily: 'GothamLight, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [GothamLight],
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const useStyle = makeStyles(() => ({
  root: {
    width: 'auto',
    minHeight: '100vh',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    color: theme.palette.text.primary
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

export { theme, useStyle };
