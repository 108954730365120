import React, { useEffect, useState } from "react";
import {
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    IconButton,
    Card,
    CardContent,
  Grid
} from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import CloseIcon from "@material-ui/icons/Close";

import { makeStyles } from "@material-ui/core/styles";
import ReturnReasonInput from "../../components/ReturnReasonInput";

import { withStoreContext } from "../../context/storeFrontContext";
import { useTranslation } from "react-i18next";
import {getSizeGuideUrl, sizeGuideUrl} from "../../utils/SizeSelectorHelper";
import { isNewProduct, isOldProduct } from "../../utils/isNewOrOldProduct";

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "100%",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    radioGroup: {
        display: "inline",
    },
    radioContainer: {
        display: "none",
        width: 0,
    },
    radioCardChecked: {
        backgroundColor: "#EEE",
    },
    returnItem: {
        justifyContent: 'center',
    }
}));

const SizeGuideModal = (props) => {
    const [open, setOpen] = useState(false);
    const { guideSource } = props;

    const { t } = useTranslation(["translation", "products"]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ textAlign: "center", marginTop: "5px" }}>
            {/* <Button color="secondary" onClick={handleClickOpen} variant="contained">
        {t("size_guide_modal.button_text")}
      </Button> */}
            <div class="css-112gsbw e7osypo6" style={{paddingTop: '20px', cursor: 'pointer', justifyContent: 'center'}} onClick={handleClickOpen} >
                <img class="css-1kv8p8n e7osypo5 lazyloaded"
                     data-src="https://static.percko.com/uploads/f3c4a6f1-3704-48e1-9668-b19de8889d47.svg"
                     src="https://static.percko.com/uploads/f3c4a6f1-3704-48e1-9668-b19de8889d47.svg" style={{width: '15px'}}/>
                <span class="css-ox8q6d e7osypo4">
                          <u>
                              <a> Guide de taille</a>
                          </u>
                    {/* <strong>
                              <font>
                                  <font>taille</font>
                              </font>
                          </strong> */}
                      </span>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={"md"}
                maxWidth={"md"}
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>{t("size_guide_modal.title")}</Box>
                        <Box>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <Typography variant="body2">
                            <div
                                dangerouslySetInnerHTML={{ __html: t("size_guide_modal.body") }}
                            ></div>
                        </Typography>
                        <div style={{ textAlign: "center" }}>
                            <img
                                src={guideSource}
                                style={{ maxWidth: "800px" }}
                                alt="guide des tailles"
                            />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const RadioCard = (props) => {
    const { src, label, value, checked } = props;
    const classes = useStyles();

    return (
        <FormControlLabel
            value={value}
            control={<Radio style={{ display: "none" }} />}
            label={
                <Card
                    style={{ maxWidth: "180px", width: "165px", height: "200px" }}
                    className={checked ? classes.radioCardChecked : ""}
                >
                    <CardContent>
                        <div style={{ textAlign: "center", fontSize: "14px" }}>
                            <img src={src} height="80px" alt="produit" />
                            <div dangerouslySetInnerHTML={{ __html: label }} />
                        </div>
                    </CardContent>
                </Card>
            }
        />
    );
};

function ExchangeDialog(props) {
    const { t, i18n } = useTranslation();
    let { lineItem, exchangeProducts, onSubmit, storeContext } = props;
    console.log(exchangeProducts);

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedProductOptions, setSelectedProductOptions] = useState({});
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [sizeGuideUrlState, setSizeGuideUrlState] = useState("");
    const [reason, setReason] = useState("");
    const [exchangeValid, setExchangeValid] = useState(true);

    const [MuiSelectValue, SetMuiSelectValue] = useState({});

    const [availableSizes, setAvailableSizes] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const preSelectSingleValueProductOptions = (product) => {
        product.options.forEach((productOption) => {
            if (productOption.values.length === 1) {
                const option = {
                    name: productOption.name,
                    value: productOption.values[0].value,
                };
                // selectedOptions[option.name] = option.value;
                // setSelectedOptions(selectedOptions);

                let newSelectedOptions = selectedOptions;
                let index = newSelectedOptions.findIndex((e) => e.name === option.name);
                if (-1 === index) {
                    newSelectedOptions.push(option);
                } else {
                    newSelectedOptions[index] = option;
                }
                console.log("newSelectedOptions", newSelectedOptions);
                setSelectedOptions(newSelectedOptions);
            }
        });
    };

    const updateAvailableSizesForOptions = (productFetched=null) => {
        let availableVariants = [];

        const product = productFetched ? productFetched : selectedProduct;

        product.variants.forEach((v) => {
            if (
                selectedOptions.every((o) => {
                    if (o.name === "Size") return true;
                    return (
                        v.selectedOptions.find(
                            (so) => so.name === o.name && so.name !== "Size"
                        ) && v.selectedOptions.find((so) => so.value === o.value)
                    );
                })
            ) {
                availableVariants.push(v);
            }
        });

        let availableSizes = [];

        console.log("availableVariants", availableVariants);

        availableVariants.forEach((curr) => {
            curr.selectedOptions.forEach((vo) => {
                if (vo.name === "Size") {
                    if (!availableSizes.includes(vo.value)) {
                        availableSizes.push(vo.value);
                    }
                }
            });
        }, {});

        setAvailableSizes(availableSizes);
    };

    const updateAvailableColorsForOptions = () => {
        // https://app.asana.com/0/1201976160742021/1204152715553973 -> remove blue color for LYNE FIT
        if (selectedProduct.title === 'Lyne FIT' || selectedProduct.title === 'LyneFit') {
            selectedProductOptions.forEach(options => {
                if (options.name === 'Color') {
                    options.values = options.values.filter(o => o !== "BLUE" && o !== "BLEU")
                }
            })

            const selectedOptionsFiltered = selectedOptions.filter(o => o.name !== "Color")
            selectedOptionsFiltered.push({name: 'Color', value: 'BLACK MICRO-PERFORATED'});

            setSelectedProductOptions(selectedProductOptions);
            setSelectedOptions(selectedOptionsFiltered);
        }
    };

    const handleProductChange = async (event) => {
        let product = exchangeProducts.find(
            (product) => event.target.value === product.id
        );
        setSelectedProduct(product);
        SetMuiSelectValue({});

        const options = product.options.map((option) => ({
            name: option.name,
            values: option.values.map((optionValue) => optionValue.value),
        }));

        setSelectedOptions([]);

        console.log("options", options);

        setSelectedProductOptions(options);
        console.log("product.options", product.options);
        preSelectSingleValueProductOptions(product);

        if (product.title.toLowerCase().includes('gilet')) {
            handleOptionChange({target: {value: "{\"name\":\"Color\",\"value\":\"Noir\"}"}}, product)
        }

        let ineligibleProduct = exchangeProducts.find(
            (product) => {
                return product.productType == "Matelas" || product.productType == "Oreiller" || product.productType == "Loop" || product.productType == "1 Matelas + Oreiller" || product.productType == "Surmatelas"
            }
        );
        let ineligibleProductListForExchange = new Set(['Matelas', 'Oreiller dorsal+', 'Loop', '1 Matelas + Oreiller', '1 Matelas Mousse + Oreiller', 'Surmatelas', 'Matelas Mousse']);
        if (ineligibleProductListForExchange.has(lineItem.title)) {
            setExchangeValid(false);
        }
    };

    const handleOptionChange = (event, productFetched=null) => {
        let option = JSON.parse(event.target.value);
        console.log("handleOptionChange", JSON.stringify(option));
        console.log("selectedProduct", selectedProduct);
        // selectedOptions[option.name] = option.value;

        let newSelectedOptions = selectedOptions;

        let index = newSelectedOptions.findIndex((e) => e.name === option.name);
        if (-1 === index) {
            newSelectedOptions.push(option);
        } else {
            newSelectedOptions[index] = option;
        }

        console.log("selectedOptions", newSelectedOptions);
        // remove undefined options
        // Object.keys(selectedOptions).forEach(key => selectedOptions[key] === undefined ? delete selectedOptions[key] : {});

        // Update availble options for selected product
        // let variants = selectedProduct.variants;
        // let variantOptions = variants.map(variant => {
        //   if ()
        //   return ({
        //     id: variant.selectedOptions,
        //     name: variant.selectedOptions,
        //     value: variant.selectedOptions
        //   })
        // })

        let newMuiSelectValue = {};
        newMuiSelectValue[option.name] = event.target.value;
        SetMuiSelectValue({ ...MuiSelectValue, ...newMuiSelectValue });

        setSelectedOptions(newSelectedOptions);

        const product = productFetched ? productFetched : selectedProduct;

        let url = getSizeGuideUrl(
            product.title,
            selectedOptions["Gender"],
            i18n.language
        );

        setSizeGuideUrlState(url);

        // getAvailableOptions();
        updateAvailableSizesForOptions(product);
        updateAvailableColorsForOptions();
        // sizeGuideUrl?.[i18n.language]?.[selectedProduct.title]?.[selectedOptions["Gender"]]);
    };

    const isvalidForExhange = (product) => {
        if (isNewProduct(lineItem.title)) {
             if (!isOldProduct(product.title)) {
                  return true;
            }
        } else {
            if (!isNewProduct(product.title)) {
              if (product.title === lineItem.title) { //not to allow the same product again for exchange
                return false;
              }else {
                return true;
              }
            }
        }
    }

    useEffect(() => {
        setSizeGuideUrlState(
            getSizeGuideUrl(
                selectedProduct.title,
                selectedOptions?.find((o) => o.name === "Gender")?.value,
                i18n.language
            )
        );

        let ineligibleProduct = exchangeProducts.find(
            (product) => {
                return product.productType == "Matelas" || product.productType == "Oreiller" || product.productType == "Loop" || product.productType == "1 Matelas + Oreiller" || product.productType == "Surmatelas"
            }
        );

        let ineligibleProductListForExchange = new Set(['Matelas', 'Oreiller dorsal+', 'Loop', '1 Matelas + Oreiller', '1 Matelas Mousse + Oreiller', 'Surmatelas', 'Matelas Mousse']);
        if (ineligibleProductListForExchange.has(lineItem.title)) {
            setExchangeValid(false);
        }
    }, [selectedProduct.id, JSON.stringify(selectedOptions)]);

    // useEffect(() => {
    //   // setSelectedOptions([]);
    //   console.log("useEffect")
    // }, [...selectedOptions.map(e => e.value)])

    const handleSubmit = (event) => {
        if (!selectedProduct.id || !selectedOptions) return;
        // selectedOptions[option.name] = option.value;
        let optionObject = selectedOptions.reduce((a, c) => {
            a[c.name] = c.value;
            return a;
        }, {});
        const variant = storeContext.client.product.helpers.variantForOptions(
            selectedProduct,
            selectedOptions.reduce((a, c) => {
                a[c.name] = c.value;
                return a;
            }, {})
        );
        console.log("submitted variant", variant);
        if (variant) {
            onSubmit({
                variant: variant,
                reason: reason,
            });
        }
        setOpen(false);
    };

    return (
        <div style={{ textAlign: "center" }}>
            <Button
                color="primary"
                onClick={handleClickOpen}
                variant="contained"
                startIcon={<SyncAltIcon />}
                disabled={!exchangeValid}
            >
                {t("ask_exchange")}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={"md"}
                maxWidth={"md"}
            >
                <DialogTitle>{t("exchange_select_dialog.title")}</DialogTitle>
                <DialogContent>
                    <form className={classes.form} noValidate>
                        <FormControl fullWidth="true" className={classes.formControl}>
                            {/* <FormLabel component="legend">Gamme</FormLabel> */}
                            <RadioGroup
                                aria-label="product"
                                name="product"
                                className={classes.radioGroup}
                                onChange={handleProductChange}
                            >
                                <Grid className={classes.returnItem} container spacing={2} alignItems="center">
                                {exchangeProducts.map((product) => (
                                    isvalidForExhange(product) ? (<Grid item xs={12} md={4} lg={4} style={{ textAlign: "center" }}>
                                        <RadioCard
                                          src={product.images[0].src}
                                          label={t(`products:${product.title}.description`)}
                                          value={product.id}
                                          checked={product.id === selectedProduct.id}
                                        />
                                    </Grid> ) : <></>
                                ))}
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                        {sizeGuideUrlState ? (
                            <div>
                                <SizeGuideModal guideSource={sizeGuideUrlState} />
                            </div>
                        ) : null}

                        {selectedProductOptions.length
                            ? selectedProductOptions.map((productOption) => {
                                return productOption.values.length > 1 ? (
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id={productOption.id + "-label"}>
                                            {t(
                                                `exchange_select_dialog.options.${productOption.name.toLowerCase()}`
                                            )}
                                        </InputLabel>
                                        <Select
                                            labelId={productOption.id}
                                            id={productOption.id}
                                            onChange={event => handleOptionChange(event, selectedProduct)}
                                            value={
                                                // getSelectValue(productOption.id)
                                                MuiSelectValue[productOption.name] || ""
                                                // selectedOptions?.find(o => o.id === productOption.id) ? JSON.stringify(selectedOptions?.find(o => o.id === productOption.id)) : ''
                                                // (selectedOptions.length >= 0 && selectedOptions.find(o => o.id === productOption.id)) ?
                                                // selectedOptions.find(o => o.name.toLowerCase() === productOption.name.toLowerCase()).value
                                                // (
                                                //   JSON.stringify({
                                                //     id: productOption.id,
                                                //     name: productOption.name,
                                                //     value: selectedOptions?.find(o => o.id === productOption.id)?.value,
                                                //   })
                                                // )
                                                // : ''
                                            }
                                            renderValue={(value) => {
                                                const option = JSON.parse(value);
                                                const text = t(
                                                    `exchange_select_dialog.values.${productOption.name.toLowerCase()}.${option.value.toLowerCase()}`
                                                );
                                                if (productOption.id !== option.id) return "";
                                                return text ?? "";
                                            }}
                                            // input={<Input />}
                                        >
                                            {productOption.name !== "Size" &&
                                                productOption.values.map((optionValue) => {
                                                    return (
                                                        <MenuItem
                                                            key={optionValue}
                                                            value={JSON.stringify({
                                                                name: productOption.name,
                                                                value: optionValue,
                                                            })}
                                                        >
                                                            {t(
                                                                `exchange_select_dialog.values.${productOption.name.toLowerCase()}.${optionValue.toLowerCase()}`
                                                            )}
                                                        </MenuItem>
                                                    );
                                                })}
                                            {productOption.name === "Size" &&
                                                availableSizes?.map((optionValue) => {
                                                    return (
                                                        <MenuItem
                                                            key={optionValue}
                                                            value={JSON.stringify({
                                                                name: productOption.name,
                                                                value: optionValue,
                                                            })}
                                                        >
                                                            {t(
                                                                `exchange_select_dialog.values.${productOption.name.toLowerCase()}.${optionValue.toLowerCase()}`
                                                            )}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                ) : null;
                            })
                            : null}
                        {/*<Divider />*/}
                        <ReturnReasonInput product={lineItem} onChange={setReason} />
                        {!reason && <div>{t("form_labels.form_error_reason")}</div>}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleSubmit} color="primary" disabled={!reason}>
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withStoreContext(ExchangeDialog);
