import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Tooltip,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const returnReasons = {
  //t-shirts
  LyneUp: [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  'T-SHIRT DISCRET - HOMME': [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  'T-SHIRT DISCRET - FEMME': [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  LyneFit: [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  'T-SHIRT DE SPORT - FEMME': [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  'T-SHIRT DE SPORT - HOMME': [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  "Lyne FIT": [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  LynePro: [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  LyneHome: [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  'GILET PRATIQUE - HOMME': [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  'GILET PRATIQUE - FEMME': [
    "faulty",
    "weak_action",
    "allergies",
    "not_comfortable",
    "wrong_product_received",
    "too_tight",
    "goes_up",
    "provoques_muscles_stiffness",
    "do_not_like_design_or_color",
    "other",
  ],
  Wavii: [
    "cannot_connect",
    "faulty",
    "do_not_vibrate_on_riksy_movements",
    "do_not_stick_on_skin",
    "not_compatible_with_phone",
    "disappointed_by_product",
    "disappointed_by_app",
    "other",
  ],
  Loop: [
    "loop_hurts",
    "faulty",
    "disappointed_by_product",
    "product_too_hard",
    "damaged_product_text",
    "cannot_use",
    "product_too_big",
    "other",
  ],
  //beds
  Matelas: [
    "too_firm",
    "too_soft",
    "too_hot",
    "defective",
    "dimension_error",
    "other",
  ],
  'Matelas Mousse': [
    "too_firm",
    "too_soft",
    "too_hot",
    "defective",
    "dimension_error",
    "other",
  ],
  "1 Matelas + Oreiller": [
    "too_firm",
    "too_soft",
    "too_hot",
    "too_thin",
    "too_thick",
    "defective",
    "dimension_error",
    "other",
  ],
  "1 Matelas Mousse + Oreiller": [
    "too_firm",
    "too_soft",
    "too_hot",
    "too_thin",
    "too_thick",
    "defective",
    "dimension_error",
    "other",
  ],
  // pillows
  Oreiller: [
    "too_firm",
    "too_soft",
    "too_hot",
    "too_thin",
    "too_thick",
    "defective",
    "other",
  ],
  'Oreiller plat': [
    "too_firm",
    "too_soft",
    "too_hot",
    "too_thin",
    "too_thick",
    "defective",
    "other",
  ],
  'Oreiller dorsal+': [
    "too_firm",
    "too_soft",
    "too_hot",
    "too_thin",
    "too_thick",
    "defective",
    "other",
  ],
  //bed spring
  "Sommier Bois Massif": [
    "too_climb",
    "defective",
    "aesthetic",
    "other"
  ],
  "Sommier Original": [
    "too_climb",
    "defective",
    "aesthetic",
    "other"
  ],
  "Sommier Coffre": [
    "too_climb",
    "defective",
    "aesthetic",
    "other"
  ],
  "Sommier zoné - électrique": [
    "too_climb",
    "defective",
    "aesthetic",
    "other"
  ],
  // mattress topper
  "Surmatelas": [
    "trop_fin",
    "trop_epais",
    "trop_mou",
    "trop_dur",
    "defective",
    "dimension_error",
    "autre"
  ],
  // mattress topper
  "Chaise ergonomique": [
    "unconfortable",
    "defective",
    "hard_to_fit",
    "product_damaged",
    "allergic_or_sensible",
    "autre"
  ],
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 200,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  container: {
    display: "flex",
  },
  icon: {
    marginTop: "36px",
    marginLeft: "10px",
  }
}));

export default function ReturnReasonInput(props) {
  const { t } = useTranslation();
  let { onChange, product } = props;
  const [reason, setReason] = useState('');
  const classes = useStyles();

  console.log(product?.title)

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl} required error={!reason}>
        <InputLabel id="return-reason-label">
          {t("form_labels.return_reason.title")}
        </InputLabel>
        <Select
          labelId="return-reason-label"
          id="return-reason"
          onChange={(event) => {
            onChange(event.target.value);
            setReason(event.target.value);
          }}
          value={reason ?? ''}
          input={<Input />}
        >
          {returnReasons?.[product?.title]?.map((reason) => (
            <MenuItem key={reason} value={reason}>
              {t(`form_labels.return_reasons.${reason}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <HtmlTooltip
          title={
          <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: t('form_labels.return_reason.info') }} />
            </React.Fragment>
          }
        >
          <InfoIcon color="disabled" className={classes.icon}/>
        </HtmlTooltip>
      {
        // reason === 'other' ?
        // (
        // // <FormControl className={classes.formControl}>
        //     // {/* <InputLabel id="return-reason-other-label">Détails</InputLabel> */}
        //     <TextField
        //     labelId="return-reason-other-label"
        //     id="return-reason-other"
        //     label="return reason"
        //     onChange={event => {
        //         reason = event.target.value;
        //       onChange(reason);
        //     }}
        //     input={<Input />}
        //     />
        // // {/* </FormControl> */}
        // )
        // :
        // null
      }
    </div>
  );
}
