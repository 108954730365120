import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import clsx from "clsx";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  StepConnector,
} from "@material-ui/core";

import ReactGA from "react-ga4";

import {Formik, Form, useFormikContext} from "formik";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import {withStoreContext} from "../../context/storeFrontContext";

import OrderSearchForm from "./Forms/OrderSearchForm";
import ReturnedProductSelectForm from "./Forms/ReturnedProductSelectForm";
import ShippingInformationForm from "./Forms/ShippingInformationForm";
import ReviewOrder from "./ReviewOrder";
import ReturnSuccess from "./ReturnSuccess";

import validationSchema from "./FormModel/validationSchema";
import returnFormModel from "./FormModel/returnFormModel";
import formInitialValues from "./FormModel/formInitialValues";

import WelcomeCard from "../../components/WelcomeCard";
import Faq from "../../components/OrderSearchFaq";
import useStyles from "./styles";
import axios from "axios";
import {useTranslation} from "react-i18next";
import isStaging from "../../utils/isStaging";

import {filterExchangeAllowedProducts} from "../../utils/allowedExchanges";
import {useEffect} from "react";
import getApiUrl from "../../utils/getApiUrl";

const {formId, formField} = returnFormModel;

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(6, 255, 232) 0%, rgb(0, 191, 173) 50%, rgb(0, 150, 136) 100%);",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(6, 255, 232) 0%, rgb(0, 191, 173) 50%, rgb(0, 150, 136) 100%);",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(0, 172, 156) 0%,rgb(0, 191, 173) 50%,rgb(3, 220, 199) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(0, 172, 156) 0%,rgb(0, 191, 173) 50%,rgb(3, 220, 199) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const ColorlibStepIcon = (props) => {
  const classes = useColorlibStepIconStyles();
  const {active, completed} = props;

  const icons = {
    1: <SearchIcon/>,
    2: <ShoppingBasketIcon/>,
    3: <LocalShippingIcon/>,
    4: <CheckCircleIcon/>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};

const AutoSubmitWithUrlParams = (props) => {
  const {values, submitForm} = useFormikContext();

  useEffect(() => {
    if (values.customer_email && values.order_name) {
      submitForm();
    }
  }, []);

  return null;
};

function ReturnPage(props) {
  const {storeContext} = props;
  const {t} = useTranslation();

  const steps = [
    {
      label: t("return_steps.order_search"),
    },
    {
      label: t("return_steps.products_select"),
    },
    {
      label: t("return_steps.shipping_info"),
    },
    {
      label: t("return_steps.confirmation"),
    },
  ];

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const [formValues, setFormValues] = useState({});
  const [orderSearchError, setOrderSearchError] = useState({});
  const [returnData, setReturnData] = useState({});
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [isBedSpringFormActive, setIsBedspringFormActive] = useState(false);
  const [isBackFromBedSpring, setIsBackFromBedSpring] = useState(false);

  const {
    location: {search},
  } = props;

  let params = queryString.parse(search);

  if (params.oid && params.e) {
    formInitialValues.order_name = params.oid;
    formInitialValues.customer_email = params.e;
  }

  useEffect(() => {
    initReactGA();
  }, [])

  const initReactGA = () => {
    console.log("initReactGA");
    ReactGA.initialize('G-HDLQXLF888');
    // Send initial test view
    ReactGA.pageview('return page initial view');
  };

  function _renderStepContent(step, formValues) {
    ReactGA.pageview(`return page step - ${step} - ${steps[step].label}`);
    setNextButtonDisabled(false);
    console.log('isBedSpringFormActive', step, isBedSpringFormActive)
    switch (step) {
      case 0:
        setNextButtonDisabled(false);
        return (
          <>
            <OrderSearchForm formField={formField}/>
          </>
        );
      case 1:
        if (formValues.lineItems.length === 0) {
          setNextButtonDisabled(true);
          return (
            <div
              dangerouslySetInnerHTML={{__html: t("no_item_for_return")}}
            ></div>
          );
        }
        return (
          <ReturnedProductSelectForm
            {...props}
            isBedSpringFormActive={isBedSpringFormActive}
            setIsBedspringFormActive={setIsBedspringFormActive}
            formValues={formValues}
            setFormValues={setFormValues}
            setIsBackFromBedSpring={setIsBackFromBedSpring}
          />
        );
      case 2:
        if (
          (!isBedSpringFormActive && formValues.lineItems.every((item) => {
            return !item.is_refund && !item.is_exchange && !item.is_replacement && !item.is_cover_request && !item.is_parts_request;
          })) || isBackFromBedSpring
        ) {
          setNextButtonDisabled(true);
          setActiveStep(1)
          setIsBackFromBedSpring(false);
          return <div>{t("no_item_selected")}</div>;
        }

        if (!isBedSpringFormActive && formValues.lineItems.every((item) => item.is_refund)) {
          setActiveStep(activeStep + 1);
        }
        return (
          <ShippingInformationForm
            formField={formField}
            formValues={formValues}
          />
        );
      case 3:
        return <ReviewOrder formValues={formValues}/>;
      default:
        return <div>Not Found</div>;
    }
  }

  async function _submitForm(values, actions) {
    let url = getApiUrl() + '/returns/submit';

    actions.setSubmitting(true);
    values.returnLineItems = values.lineItems.filter((e) => {
      return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
    });

    const bedProducts = ['Matelas', '1 Matelas + Oreiller', '1 Matelas Mousse + Oreiller', 'Matelas Mousse'];
    const bedSpringProducts = ['Sommier Bois Massif', 'Sommier Coffre', 'Sommier Original', 'Sommier zoné - électrique'];
    const bedSpringElectricProducts = ['Sommier zoné - électrique'];
    const pillowProducts = ['Oreiller', 'Oreiller plat'];
    const pillowDorsalPlusProducts = ['Oreiller dorsal+'];
    const mattressTopperProducts = ['Surmatelas'];
    const chairProducts = ['Chaise ergonomique'];

    console.log("values", values);
    let nightProductsList = values.returnLineItems.filter(
      (product) => {
        return bedProducts.includes(product.title);
      }
    );
    let pillowProductsList = values.returnLineItems.filter(
      (product) => {
        return pillowProducts.includes(product.title);
      }
    );
    let pillowDorsalPlusProductsList = values.returnLineItems.filter(
      (product) => {
        return pillowDorsalPlusProducts.includes(product.title);
      }
    );
    let textileProductsList = values.returnLineItems.filter(
      (product) => {
        return (!bedProducts.includes(product.title)) && (!bedSpringProducts.includes(product.title))
          && (!pillowProducts.includes(product.title) && (!chairProducts.includes(product.title)) && (!mattressTopperProducts.includes(product.title)))
          && (!pillowDorsalPlusProducts.includes(product.title))
      }
    );
    let bedSpringProductsList = values.returnLineItems.filter(
      (product) => {
        return bedSpringProducts.includes(product.title) && !bedSpringElectricProducts.includes(product.title);
      }
    );
    let bedSpringElectricProductsList = values.returnLineItems.filter(
      (product) => {
        return bedSpringElectricProducts.includes(product.title);
      }
    );
    let mattressTopperProductsList = values.returnLineItems.filter(
      (product) => {
        return mattressTopperProducts.includes(product.title);
      }
    );
    let chairProductsList = values.returnLineItems.filter(
      (product) => {
        return chairProducts.includes(product.title);
      }
    );


    let returnData = [];
    let returnProp = [];

    async function processProducts(processedProductTypes = []) {

      const processReturnQueryAndProcessProducts = async () => {
        return returnPrepareQuery(values.order_name, values.customer_email)
          .then(async (response) => {
            let returnData = response.data;

            values.id = returnData.id;
            // values.lineItems = returnData.line_items_available_for_return;
            values.order_id = returnData.order_id;
            values.order_name = returnData.order_name;
            values.order_date = returnData.order_date;
            values.customer = returnData.customer;
            values.shopify_store = storeContext.shopUrl;
            values.returnedLineItems = returnData.returned_line_items;

            values.lineItems = filterExchangeAllowedProducts(
              returnData.line_items_available_for_return
            );

            values.shipping_address = returnData.shipping_address;

            try {
              await processProducts(processedProductTypes);
            } catch (e) {
              if (e.response?.data?.message) {
                setOrderSearchError({
                  message: t(`errors.order.${e.response.data.code.toString()}`),
                });
              } else {
                setOrderSearchError(e);
              }
            }

          })
          .catch((e) => {
            if (e.response?.data?.message) {
              setOrderSearchError({
                message: t(`errors.order.${e.response.data.code.toString()}`),
              });
            } else {
              setOrderSearchError(e);
            }
          });
      }

      if (textileProductsList && textileProductsList.length > 0 && !processedProductTypes.includes('TEXTILE_PRODUCT')) {
        values.returnLineItems = textileProductsList;

        if (values.refund_line_items && values.refund_line_items.length > 0) {
          values.lineItems = values.returnLineItems.concat(values.refund_line_items);
        }
        await axios
          .post(url, values)
          .then(async (response) => {
            values.returnLineItems = values.lineItems.filter((e) => {
              return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
            });
            returnData.push(response.data.return_line_items);
            returnProp.push(response.data);
            processedProductTypes.push('TEXTILE_PRODUCT');

            await processReturnQueryAndProcessProducts();
          })
          .catch((e) => {
            console.error(e);
            actions.setSubmitting(false);
          });
      } else if (nightProductsList && nightProductsList.length > 0 && !processedProductTypes.includes('NIGHT_PRODUCT')) {
        values.returnLineItems = nightProductsList;
        values.lineItems = values.returnLineItems.concat(values.refund_line_items);
        await axios
          .post(url, values)
          .then(async (response) => {
            values.returnLineItems = values.lineItems.filter((e) => {
              return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
            });

            returnData = response.data.return_line_items.concat(returnData);
            returnProp.push(response.data);
            processedProductTypes.push('NIGHT_PRODUCT');

            await processReturnQueryAndProcessProducts();
          })
          .catch((e) => {
            console.error(e);
            actions.setSubmitting(false);
          });
      } else if (pillowProductsList && pillowProductsList.length > 0 && !processedProductTypes.includes('PILLOW_PRODUCT')) {
        values.returnLineItems = pillowProductsList;
        values.lineItems = values.returnLineItems.concat(values.refund_line_items);

        await axios
          .post(url, values)
          .then(async (response) => {
            values.returnLineItems = values.lineItems.filter((e) => {
              return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
            });
            returnProp.push(response.data);
            returnData = response.data.return_line_items.concat(returnData);
            processedProductTypes.push('PILLOW_PRODUCT');

            await processReturnQueryAndProcessProducts();

          })
          .catch((e) => {
            console.error(e);
            actions.setSubmitting(false);
          });

      } else if (pillowDorsalPlusProductsList && pillowDorsalPlusProductsList.length > 0 && !processedProductTypes.includes('PILLOW_DORSAL_PLUS_PRODUCT')) {
        values.returnLineItems = pillowDorsalPlusProductsList;
        values.lineItems = values.returnLineItems.concat(values.refund_line_items);

        await axios
          .post(url, values)
          .then(async (response) => {
            values.returnLineItems = values.lineItems.filter((e) => {
              return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
            });
            returnProp.push(response.data);
            returnData = response.data.return_line_items.concat(returnData);
            processedProductTypes.push('PILLOW_DORSAL_PLUS_PRODUCT');

            await processReturnQueryAndProcessProducts();

          })
          .catch((e) => {
            console.error(e);
            actions.setSubmitting(false);
          });

      } else if (mattressTopperProductsList && mattressTopperProductsList.length > 0 && !processedProductTypes.includes('MATTRESS_TOPPER_PRODUCT')) {
        values.returnLineItems = mattressTopperProductsList;
        values.lineItems = values.returnLineItems.concat(values.refund_line_items);

        await axios
          .post(url, values)
          .then(async (response) => {
            values.returnLineItems = values.lineItems.filter((e) => {
              return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
            });
            returnProp.push(response.data);
            returnData = response.data.return_line_items.concat(returnData);
            processedProductTypes.push('MATTRESS_TOPPER_PRODUCT');

            await processReturnQueryAndProcessProducts();

          })
          .catch((e) => {
            console.error(e);
            actions.setSubmitting(false);
          });

      } else if (chairProductsList && chairProductsList.length > 0 && !processedProductTypes.includes('CHAIR_PRODUCT')) {
        values.returnLineItems = chairProductsList;
        values.lineItems = values.returnLineItems.concat(values.refund_line_items);

        await axios
          .post(url, values)
          .then(async (response) => {
            values.returnLineItems = values.lineItems.filter((e) => {
              return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
            });
            returnProp.push(response.data);
            returnData = response.data.return_line_items.concat(returnData);
            processedProductTypes.push('CHAIR_PRODUCT');

            await processReturnQueryAndProcessProducts();

          })
          .catch((e) => {
            console.error(e);
            actions.setSubmitting(false);
          });

      } else if (bedSpringProductsList && bedSpringProductsList.length > 0 && !processedProductTypes.includes('BED_SPRING_PRODUCT')) {
        values.returnLineItems = bedSpringProductsList;
        values.lineItems = values.returnLineItems.concat(values.refund_line_items);

        await axios
          .post(url, values)
          .then(async (response) => {
            values.returnLineItems = values.lineItems.filter((e) => {
              return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
            });
            returnProp.push(response.data);
            returnData = response.data.return_line_items.concat(returnData);
            processedProductTypes.push('BED_SPRING_PRODUCT');

            await processReturnQueryAndProcessProducts();
          })
          .catch((e) => {
            console.error(e);
            actions.setSubmitting(false);
          });

      } else if (bedSpringElectricProductsList && bedSpringElectricProductsList.length > 0 && !bedSpringElectricProductsList.includes('BED_SPRING_ELECTRIC_PRODUCT')) {
        values.returnLineItems = bedSpringElectricProductsList;
        values.lineItems = values.returnLineItems.concat(values.refund_line_items);

        await axios
          .post(url, values)
          .then((response) => {
            values.returnLineItems = values.lineItems.filter((e) => {
              return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
            });
            returnProp.push(response.data);
            returnData = response.data.return_line_items.concat(returnData);
            processedProductTypes.push('BED_SPRING_ELECTRIC_PRODUCT');
          })
          .catch((e) => {
            console.error(e);
            actions.setSubmitting(false);
          });

      }
    }

    await processProducts([]);
    /*else if (chairProductsList && chairProductsList.length > 0) {
      values.returnLineItems = bedSpringProductsList;
      values.lineItems = values.returnLineItems.concat(values.refund_line_items);

      axios
        .post(url, values)
        .then((response) => {
          values.returnLineItems = values.lineItems.filter((e) => {
            return e && (e.is_refund || e.is_exchange || e.is_replacement || e.is_cover_request || e.is_parts_request);
          });
          returnProp.push(response.data);
          returnData = response.data.return_line_items.concat(returnData);

          // console.log('returnData', returnData);
          setReturnData(returnProp);
          actions.setSubmitting(false);
          setActiveStep(activeStep + 1);
        })
        .catch((e) => {
          console.error(e);
          actions.setSubmitting(false);
        });

    }*/


    // console.log('returnData', returnData);
    //stop submitting form
    setReturnData(returnProp);
    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }

  const returnPrepareQuery = async (orderName, customerEmail) => {
    let url = getApiUrl() + '/returns/prepare';
    const data = {
      orderName,
      customerEmail,
      shop_url: storeContext.shopUrl,
    };

    return await axios.post(url, data);
  };

  const _searchOrderStepSubmit = (values, actions) => {
    setOrderSearchError({});
    actions.setSubmitting(true);

    returnPrepareQuery(values.order_name, values.customer_email)
      .then(async (response) => {
        const returnData = response.data;
        values.id = returnData.id;

        console.log('returnData-prepare', returnData)
        // values.lineItems = returnData.line_items_available_for_return;
        values.order_id = returnData.order_id;
        values.order_name = returnData.order_name;
        values.order_date = returnData.order_date;
        values.customer = returnData.customer;
        values.shopify_store = storeContext.shopUrl;
        values.returnedLineItems = returnData.returned_line_items;

        values.lineItems = filterExchangeAllowedProducts(
          returnData.line_items_available_for_return
        );
        // values.lineItems = Array.from(new Set(values.lineItems.map(JSON.stringify))).map(JSON.parse);

        values.shipping_address = returnData.shipping_address;

        setFormValues(values);
        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          console.log("error", e.response?.data);
          setOrderSearchError({
            message: t(`errors.order.${e.response.data.code.toString()}`),
          });
        } else {
          setOrderSearchError(e);
        }
        actions.setSubmitting(false);
      });
  };

  function _handleSubmit(values, actions) {
    if (activeStep === 0) {
      _searchOrderStepSubmit(values, actions);
    } else {
      if (isLastStep) {
        _submitForm(values, actions);
      } else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }
  }

  function _handleBack() {
    if (activeStep === 3) {
      setActiveStep(activeStep - 2);
    } else {
        setActiveStep(activeStep - 1);
    }
  }

  return (
    <React.Fragment>
      <div className={classes.logoContainer}>
        <img
          src="https://percko-website-static.s3.eu-west-3.amazonaws.com/uploads/e0d0dd8d-277b-4edc-9440-4f8109cf2c36.jpeg"
          className={classes.logo}
          alt="logo"
        />
      </div>
      <Typography component="h1" variant="h4" align="center">
        {t("return_form.title")}
      </Typography>
      {activeStep === 0 ? <WelcomeCard/> : null}
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepper}
        connector={<ColorlibConnector/>}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <ReturnSuccess returnData={returnData}/>
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({isSubmitting}) => (
              <Form id={formId} autoComplete="on">
                {_renderStepContent(activeStep, formValues)}

                {orderSearchError ? (
                  <div> {orderSearchError.message} </div>
                ) : null}

                {!isBedSpringFormActive && <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      {t("back")}
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting || nextButtonDisabled}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? t("confirm_return") : t("next")}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <AutoSubmitWithUrlParams/>
                </div>}
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
      <React.Fragment>{activeStep === 0 ? <Faq/> : null}</React.Fragment>

    </React.Fragment>
  );
}

export default withStoreContext(withRouter(ReturnPage));
