const isStaging = require("./isStaging");

let tokens = [
    {
        storeUrl: 'percko-eur-staging.myshopify.com',
        accessToken: '3d0c2e826216b6269165537ca04f6412',
    },
    {
        storeUrl: 'percko-gbp-staging.myshopify.com',
        accessToken: '66fac9e4651a740545477ed830520a42',
    },
    {
        storeUrl: 'percko-usd-staging.myshopify.com',
        accessToken: '27c04c4b55c8ec84d4b63b803745cf77',
    },
    {
        storeUrl: 'percko-cad-staging.myshopify.com',
        accessToken: 'c21e96eb338cb77d6cbe296daa9d3fdc',
    }
];

if (!isStaging()) {
    tokens = [
        {
            storeUrl: 'percko-eur.myshopify.com',
            accessToken: '9221a0151ddc9d634ec31bbd155c3bfc',
        },
        {
            storeUrl: 'percko-gbp.myshopify.com',
            accessToken: '759259fc02f68d6e2dc88473f7fcbe2d',
        },
        {
            storeUrl: 'percko-usd.myshopify.com',
            accessToken: 'b0cd45a48a48131830f3655d105a8140',
        },
        {
            storeUrl: 'percko-cad.myshopify.com',
            accessToken: 'd8b11273a8f53097f7a7fad5aa4e774c',
        }
    ];
}

export const getStorefrontAccessTokenFromUrl = (url) => {
    const creds = tokens.find(s => s.storeUrl === url);
    return creds.accessToken ? creds.accessToken : '';
}
