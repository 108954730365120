import * as Yup from 'yup';
import 'yup-phone';
import returnFormModel from './returnFormModel';

const {
  formField: {
    order_name,
    customer_email,
    shipping_address,
  }
} = returnFormModel;

export default [
  Yup.object().shape({
    [order_name.name]: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .min(3)
    .required('Required'),
    [customer_email.name]: Yup.string().email('Invalid email address').required('Required'),
  }),
  Yup.object().shape({}),
  Yup.object().shape({
    // [shipping_address.first_name.name]: Yup.string()
    //   .min(3)
    //   .required('Required'),
    // [shipping_address.last_name.name] : Yup.string()
    //   .min(3)
    //   .required('Required'),
    // [shipping_address.city] : Yup.string()
    //   .min(3)
    //   .required('Required'),
  })
  //   [shippingInfo.address1.name] : Yup.string()
  //     .min(3)
  //     .required('Required'),

  //   [shippingInfo.company] : Yup.string(),
  //   [shippingInfo.country] : Yup.string()
  //     .min(3)
  //     .required('Required'),
  //   [shippingInfo.province] : Yup.string(),
  //   [shippingInfo.zip] : Yup.string()
  //   .min(3)
  //   .required('Required'),
  //   [shippingInfo.phone] : Yup.string().phone().required(),
  // })
];
