import React from 'react';
import { Link, Typography } from '@material-ui/core/';

export default function Footer() {
  return (
    <Typography variant="body2" style={{color: 'white'}} color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://percko.com/">
        {'Percko '} 
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}
